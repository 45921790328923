$lightGreen: #f6faff;
$white: #fff;
$grey: #626466;
$lightGrey: #9b9fb7;
$lightBlack: #5e666c;
$black: #363738;
$darkGrey: #bebebe;
$blue: #008cff;
$green: #3bc185;
$red: red;
$lightWhite: #f1f1f1;
$peacock: #0077b5;

.c-peacock {
    color: $peacock;
}
.c-red {
    color: $red;
}
.c-green {
    color: $green;
}
.c-blue {
    color: $blue;
}
.c-darkGrey {
    color: $darkGrey;
}
.c-white {
    color: $white;
}
.c-grey {
    color: $grey;
}
.b-lightGreen {
    background-color: $lightGreen;
}
.c-lightGrey {
    color: $lightGrey;
}
.c-lightBlack {
    color: $lightBlack;
}

.c-black {
    color: $black;
}

.c-lightWhite {
    color: $lightWhite;
}
